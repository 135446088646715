@import 'variables.scss';
@import 'mixins.scss';

.simplebar-content {
  .weekday {
    top: -1px !important;
    position: relative;
  }
}

.schedule-block {
  margin-bottom: $padding30*3;
  &:last-child {
    margin-bottom: 0;
  }

  @include respond-to(pad) {
    margin-bottom: 0;
  }

  .weekday {
    margin-bottom: 25px;
    @include respond-to(pad) {
      font-size: $font-size-pad;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #fff;
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
      padding: 15px 0;
      background: $color-black;
      margin-top: -1px;
      position: sticky;
      top: $header-height;
      z-index: 1;
      margin-bottom: 0;
      transform: translate3d(0, 0, 0);
    }
    @include respond-to(phone) {
      padding: 10px 0 8px !important;
    }
  }

  .schedule-item {
    a {
      border-bottom: 1px solid #fff;
      display: block;
      padding: $padding20 0 21px;
      position: relative;

      @include respond-to(pad) {
        border-bottom: none;
        padding: $padding20*2 $padding30;

        &:after {
          content: ' ';
          background: #fff;
          width: 50%;
          height: 1px;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }

      @include respond-to(phone) {
        padding: $padding20 $padding20;
      }

      .schedule-item-name {
        transition: color ease-in-out .1s;
      }

      .schedule-item-date {
        position: absolute;
        top: 50%;
        left: 50%;
        white-space: nowrap;
        transform: translate(-50%, -50%);
        transition: opacity ease-in-out .1s;
        opacity: 0;
        @include respond-to(pad) {
          display: none;
        }
      }

      &:hover {
        .schedule-item-date {
          opacity: 1;
        }

        .schedule-item-name {
          color: $color-gray-disabled;
        }
      }
    }

    &:last-child {
      a { border-bottom: none; }
    }

    &.active a:hover .schedule-item-date {
      display: none;
    }
  }
}

.content {
  position: relative;
}

.schedule-slot, .archive-episode-wrapper, .live-playlist-page {
  h1 {
    @include respond-to(pad) {
      font-size: $font-size-pad-med;
      margin-bottom: $padding20*2;
    }
    @include respond-to(phone) {
      margin-bottom: $padding20;
    }
  }

  .image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: calc(24vh + 26px);
    z-index: 1;
    @include respond-to(pad) {
      height: calc(22vh + 7vw/1.6);
    }
  }

  .text-container {
    position: relative;
    z-index: 2;
    @include respond-to(pad) {
      padding-top: 22vh;
      width: 80%;
      margin: 0 auto;
    }
  }

  h2, h3 {
    font-size: $font-size-medium;
    margin: 20px auto 22px;
    line-height: 1.25;

    @include respond-to(pad) {
      font-size: $font-size-pad-med;
      line-height: 1.1;
      margin-bottom: $padding20*2;
    }

    @include respond-to(phone) {
      margin-bottom: $padding20;
    }
  }

  .description, .playlist, .hosts {
    font-size: $font-size-text;
    font-family: 'Alegreya', serif;
    line-height: 1.3;
    margin: 0 auto;
    font-weight: bold;

    @include respond-to(pad) {
      font-size: $font-size-pad-descr;
    }
  }

  .hosts {
    div, p { padding: 0; }
  }

  .play-episode {
    margin-top: 30px;
    margin-bottom: 34px;

    @include respond-to(pad) {
      margin: $padding20*2.5 auto $padding20*3;
    }

    span {
      font-size: 32px;
      border: 1px solid #fff;
      padding: $padding20 $padding30;
      display: inline-block;
      min-width: 180px;
      text-align: center;
      cursor: pointer;

      @include respond-to(pad) {
        font-size: $font-size-pad-med;
        min-width: 36vw;
      }
    }
  }

  .playlist {
    text-align: left;

    li {
      border-bottom: 1px dashed #fff;
      padding: 10px 0;

      &.breakmark {
        border-bottom: 2px solid #fff;
      }

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }

      @include respond-to(pad) {
        padding: 15px 0;
      }
    }
  }
}

.live-playlist-page {
  .playlist {
    margin-top: 26px;
    li {
      &:first-child {
        padding-top: 10px;
        border-top: 1px solid #fff;
      }
    }
  }
}

.startpage {
  h3 {
    margin-bottom: 28px;
    font-size: 40px;
  }
}

.live-playlist-page {
  h1 {
    margin-bottom: $padding20;
  }
}
