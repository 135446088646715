@import 'variables.scss';
@import 'mixins.scss';

.charts-list {
  margin-bottom: $padding30*3;
  &:last-child {
    margin-bottom: 0;
  }

  .heading {
    margin-bottom: 25px;
    @include respond-to(pad) {
      padding-top: $padding30;
    }
  }

  .episodes-date {
    color: $color-black;
    background: #fff;
    display: inline-block;
    padding: 2px $padding20;
    margin-bottom: 3px;
    @include respond-to(pad) {
      font-size: $font-size-pad;
      margin-bottom: 0;
      padding: 4px $padding30;
    }
  }

  .chart-items {
    margin-bottom: $padding30*3;
    &:last-child {
      margin-bottom: 0;
    }

    a {
      border-bottom: 1px solid #fff;
      display: block;
      padding: $padding20 0 22px;
      position: relative;
      @include respond-to(pad) {
        border-bottom: none;
        padding: $padding20*2 $padding30;

        &:after {
          content: ' ';
          background: #fff;
          width: 50%;
          height: 1px;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }
    }

    .programs-list {
      font-size: $font-size-medium;
      margin-top: 8px;
      margin-bottom: 2px;
      @include respond-to(pad) {
        font-size: $font-size-pad-med;
        margin-top: 15px;
        margin-bottom: 6px;
      }
    }
  }
}

.program-list ul.items {
  margin-bottom: $padding30*3;
}

  .load-more {
    span {
      font-size: 30px;
      border: 1px solid #fff;
      padding: $padding20 $padding30;
      display: inline-block;
      min-width: 180px;
      text-align: center;
      cursor: pointer;
    }
  }


.show-chart {
  @include respond-to(pad) {
    padding-top: 80px;
    width: 80%;
    margin: 0 auto;
  }

  h1 {
    @include respond-to(pad) {
      font-size: $font-size-pad-med;
      margin-bottom: $padding20*2;
    }
  }

  .chart-author {
    font-size: $font-size-medium;
    margin: 20px auto 22px;
    line-height: 1.2;
    @include respond-to(pad) {
      font-size: $font-size-pad-med;
      margin-bottom: $padding20*2;
    }
  }

  .chart-text {
    font-size: $font-size-text;
    font-family: 'Alegreya', serif;
    line-height: 1.3;
    font-weight: bold;

    text-align: left;

    @include respond-to(pad) {
      font-size: $font-size-pad-descr;
      margin: 0 auto $padding30;
    }

    ul {
      @include respond-to(pad) {
        margin-bottom: $padding30;
      }
    }

    li {
      border-top: 1px solid #fff;
      padding: 10px 0;

      &:first-child {
        padding-top: 0;
        border-top: none;
      }
    }
  }
}
