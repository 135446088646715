@import 'variables.scss';
@import 'mixins.scss';

.main-loading {
  display: flex;
  width: 100%;
  height: 100vh;
}

.container {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  padding-top: $header-height;
  @include respond-to(pad) {
    display: block;
    height: auto;
    overflow: visible;
  }
}

.wrapper {
  display: flex;
  width: 100%;
}

.main-menu {
  border-right: 1px solid #fff;
  @include respond-to(pad) {
    border-right: none;
  }
}

.content {
  border-left: 1px solid #fff;
  @include respond-to(pad) {
    border-left: none;
  }
}

.simplebar-scrollbar:before {
  box-shadow: 0 0 1px #f9f9f9;
}

.col {
  overflow-y: scroll;
  overflow-x: auto;
  padding: 0 $padding30*2 $padding30*3;
  padding-top: 24vh;
  @include respond-to(pad) {
    padding: 0;
    overflow: visible;
  }
}

[data-simplebar] {
  .col {
    position: relative;
    overflow-y: inherit;
    overflow-x: inherit;
  }
}

.col-33 {
  width: calc(100%/3);
  @include respond-to(pad) {
    width: 100%;
  }
}

.col-50 {
  width: 50%;
  @include respond-to(pad) {
    width: 100%;
  }
}

.col-66 {
  width: calc(100%/3*2);
  @include respond-to(pad) {
    width: 100%;
  }
}

.mobile-screen-first {
  @include respond-to(pad) {
    .wrapper {
      display: none;
    }

    .main-menu {
      position: fixed;
      top: $header-height;
      left: 0;
      right: 0;
      bottom: 0;

      ul {
        position: absolute;
        top: 49%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
  }
}

.mobile-screen-second {
  @include respond-to(pad) {
    .content {
      display: none;
    }

    .main-menu {
      li {
        display: none;

        &.active {
          display: block;
          border-bottom: 1px solid #fff;
          padding: 15px 0;
        }
      }
    }

    &.contacts {
      .submenu ul {
        padding: 10vh 0;
     }
    }
  }
  @include respond-to(phone) {
    .main-menu {
      li.active {
        padding: 10px 0 8px;
      }
    }
  }
}

.mobile-screen-third {
  @include respond-to(pad) {
    .submenu {
      display: none;
    }

    .main-menu {
      li {
        display: none;

        &.active {
          display: block;
          border-bottom: 1px solid #fff;
          padding: 15px 0;
        }
      }
    }

    .content {
      padding-bottom: $padding20*3;
    }

    &.contacts {
      .wrapper {
        display: block;
      }

      .submenu {
        display: block;

        li {
          display: none;

          &.active {
            display: block;
            border-bottom: 1px solid #fff;
            padding: 15px 0;
          }
        }
      }
    }
  }
  @include respond-to(phone) {
    .main-menu, .submenu {
      li.active {
        padding: 10px 0 8px !important;
      }
    }
  }
}
