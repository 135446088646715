@import './variables.scss';
@import 'mixins.scss';

div.announcements-container {
  width: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  opacity: 1;
  transition: transform .5s ease-in-out;
  background-color: $color-black;

  &.scrolled {
    transform: translateY(-100%);
  }

  .announcement {
    float: left;
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 87vh;
    text-align: center;

    &.bg-fill .announcement-image {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    &.bg-tile .announcement-image {
      background-size: 20vw auto;
    }

    @include respond-to(pad) {
      height: calc(100% - 13vh);
    }
  }

  .announcement-image, .announcement-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 87vh;
    @include respond-to(pad) {
      height: 100%;
    }
  }

  .announcements-wrapper, .swipe-wrap {
    @include respond-to(pad) {
      height: 100%;
    }
  }

  .announcement-overlay {
    opacity: .5;
  }

  .announcement-content {
    width: 100%;
    position: relative;
    z-index: 1;
    @include respond-to(pad) {
      height: 100%;
    }
  }

  .announcement-header {
    height: 87vh;
    padding-top: $header-height*1.5;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @include respond-to(pad) {
      height: 100%;
    }
  }

  .announcement-wrapper {
    width: 100%;
  }

  .announcement-header-title {
    @include respond-to(phone) {
      font-size: 12vw !important;
      margin-bottom: 18px !important;
    }

    &.announcement-header-title-large {
      font-size: 10.5vw;
      margin-bottom: 3.6vh;
      line-height: .9;
    }

    &.announcement-header-title-medium {
      font-size: 9.5vw;
      margin-bottom: 2.5vh;
      line-height: .9;
    }

    &.announcement-header-title-small {
      font-size: 7.5vw;
      margin-bottom: 3.5vh;
      line-height: .88;
    }

    p {
      font-family: "plakat", Arial, serif;
      line-height: inherit;
      margin: 0;
      font-size: inherit;
    }
  }

  .announcement-header-text {
    max-width: 640px;
    padding: 0 20px;
    margin: 0 auto;
    font-size: 2vw;
    @include respond-to(phone) {
      font-size: 4vw;
    }

    p {
      font-family: "plakat", Arial, serif;
      font-size: inherit;
      line-height: 1.15;

      a {
        background-image: linear-gradient(to right, #fff 100%, transparent 100%);
        background-position: 0 1.04em;
        background-repeat: repeat-x;
        background-size: 8px 2px;
      }
    }
  }

  .program {
    .announcement-header-text p {
      font-size: 1.3vw;
      font-family: 'Alegreya', serif;
      line-height: 1.3;
      font-weight: bold;
      margin: 0;
      @include respond-to(phone) {
        font-size: 3.5vw;
      }
    }
  }

  .announcement-footer {
    display: flex;
    background-color: $color-black;
    border-top: 1px solid #fff;
    height: 13vh;
    font-size: 3vw;
    cursor: pointer;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 0 $padding20;
    z-index: 99;
    @include respond-to(pad) {
      font-size: $font-size-pad;
      line-height: 1;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      .announcement-content {
        height: auto;
      }
    }
  }
}

.announcer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  background: #660000;
  display: flex;
  cursor: pointer;
  padding: 7px 0;

  @keyframes announcer-marquee {
    0% { transform: translateX(0); }
    100% { transform: translateX(-50%); }
  }

  .announcer-wrapper {
    font-size: 24px;
    white-space: nowrap;
    animation: announcer-marquee 7s linear infinite;
    position: relative;
    display: inline-block;

    @include respond-to(pad) {
      font-size: $font-size-pad;
      line-height: 1;
      animation: announcer-marquee 14s linear infinite;
    }

    span:after {
      content: "\007C\00a0\00a0";
      color: transparent;
    }

    .announcer-text-last {
      position: absolute;
      top: 0;
      right: 0;
      transform: translateX(100%);
      @include respond-to(pad) {
        display: none;
      }
    }
  }
}

.announcements-controls {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 99999;
  font-size: 0;
  background: rgba(0,0,0,.7);
  border-bottom: 1px solid #fff;
  padding: 10px 0;

  .announcements-bullet {
    width: 18px;
    height: 18px;
    background: #fff;
    opacity: .2;
    font-size: 0;
    display: inline-block;
    border-radius: 100%;
    margin-right: 10px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      opacity: 1;
    }
  }
}
