@import 'variables.scss';
@import 'mixins.scss';

html, body {
  width: 100%;
}

body {
  padding: 0;
  font-family: "plakat", Arial, serif;
  font-size: $font-size-big;
  text-rendering: optimizeLegibility;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  line-height: 1.05;
  color: #fff;
  background: $color-black;

  @include respond-to(pad) {
    font-size: $font-size-pad;
    line-height: 1;
  }
}

* {
  box-sizing: border-box;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}


header.header {
  position: fixed;
  background: $color-black;
  border-bottom: 1px solid #fff;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  overflow: hidden;
  .logo {
    width: $logo-width;
    a {
      font-size: $font-size-small;
      border-right: 1px solid #fff;
      height: $header-height;
      display: block;
      text-align: center;
      line-height: $header-height + 1px;
    }
  }
}

a {
  color: #fff;
  text-decoration: none;
}

h1, h2, h3, h4 {
  margin: 0;
  font-size: $font-size-big;
  line-height: 1.05;
}

p {
  font-size: $font-size-text;
  font-family: 'Alegreya', serif;
  line-height: 1.3;
  font-weight: bold;
  @include respond-to(pad) {
    font-size: $font-size-pad-descr;
    margin: 0 auto $padding30;
  }
  @include respond-to(phone) {
    margin-bottom: $padding20;
  }
}

li {
  &.active a {
    color: #666;
   -webkit-text-fill-color: transparent;
   -webkit-text-stroke-width: 1px;
   -webkit-text-stroke-color: #fff;
  }
}

.center {
  text-align: center;
}

.hidden-mobile {
  @include respond-to(pad) {
    display: none !important;
  }
}

.not-found {
  text-align: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .not-found-center {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-61%);
    font-size: 11.5vw;
    line-height: .9;
  }

  .back {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    background-color: $color-black;
    border-top: 1px solid #fff;
    height: 13vh;
    font-size: 3vw;
    cursor: pointer;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 0 $padding20;
    z-index: 99;
    @include respond-to(pad) {
      font-size: $font-size-pad;
      line-height: 1;
    }
  }
}