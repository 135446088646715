@import 'variables.scss';
@import 'mixins.scss';


.contacts-wrapper {
  text-align: center;

  @include respond-to(pad) {
    padding-top: 80px;
    width: 80%;
    margin: 0 auto;
  }

  h1, ul {
    margin-bottom: 42px;
    @include respond-to(pad) {
      font-size: $font-size-pad-med;
      margin-bottom: $padding20*2;
    }
  }

  p {
    width: 80%;
    margin: 0 auto 24px;

    a {
      text-decoration: underline;
      background-image: none;
    }
  }

  a {
    background-image: linear-gradient(to right, #fff 100%, transparent 100%);
    background-position: 0 1.04em;
    background-repeat: repeat-x;
    background-size: 8px 2px;
  }
}

.staff.contacts-wrapper {
  width: 80%;
  margin: 0 auto;
}
