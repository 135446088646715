$color-black: #000000;
$color-red: #FF0000;
$color-gray: #D8D8D8;
$color-gray-disabled: #343434;

$padding20: 20px;
$padding30: 30px;
$padding-top: 23vh;

$font-size-big: 40px;
$font-size-medium: 18px;
$font-size-small: 15px;
$font-size-text: 16px;

$font-size-pad: 11vw;
$font-size-pad-med: 7vw;
$font-size-pad-descr: 5.5vw;

$logo-width: 127px;
$header-height: 40px;

$size-pad: 1024px;
$size-phone: 500px;