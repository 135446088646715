@font-face {
  font-family: 'plakat';
  src: url('/assets/fonts/plakat-webfont.woff2') format('woff2'),
       url('/assets/fonts/plakat-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.bordered-font {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
}