@import 'variables.scss';
@import 'mixins.scss';

$filter-offset: 72px;

.main-app.archive {
  .main-menu {
    background: $color-gray;
    border-right-color: $color-black;
    a {
      color: $color-black;
    }
    .active a {
      -webkit-text-stroke-color: $color-black;
      color: transparent;
    }
    @include respond-to(pad) {
      .active {
        border-bottom-color: $color-black !important;
      }
    }
  }

  &.archive-filter-active {
    &.filter-datepicker {
      .submenu {
        margin-top: 72px;
        @include respond-to(pad) {
          margin-top: 0;
        }
      }
    }
    .submenu {
      padding: 0;
      border-left-color: #fff;
      background: $color-black;
      margin: 147px auto $filter-offset;

      &[data-simplebar] {
        padding: 0;
        .col {
          padding: 0;
        }
      }

      @include respond-to(pad) {
        margin-bottom: calc(11vw + 30px);
        margin-top: 0;
      }

      @include respond-to(phone) {
        margin-bottom: calc(11vw + 20px);
      }

      .archive-list {
        display: none;
      }
    }
  }

  .submenu {
    background: $color-gray;
    color: $color-black;
    padding-bottom: 120px;
    position: relative;
    &[data-simplebar] {
      padding-bottom: 0;
      .col {
        padding-bottom: 120px;
      }
    }
    @include respond-to(pad) {
      padding-bottom: calc(11vw + 30px);
      min-height: 75vh;
    }
  }

  .content {
    background: $color-gray;
    border-left-color: $color-black;
  }

  &.archive-episode {
    .content {
      background: $color-black;
      border-left-color: #fff;
    }
  }

  &.archive-filter-active {
    .main-menu, .content {
      border-color: #fff;
    }
  }

  .archive-heading {
    margin-bottom: $padding20*2;
    @include respond-to(pad) {
      padding-top: $padding30;
    }
    @include respond-to(phone) {
      margin-bottom: $padding20;
      padding-top: $padding20;
    }
  }

  .episodes {
    margin-bottom: $padding20*2.5;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .episode {
    a {
      border-bottom: 1px solid $color-black;
      display: block;
      padding: $padding20 0 22px;
      color: $color-black;
      position: relative;

      @include respond-to(pad) {
        border-bottom: none;
        padding: $padding20*2 $padding30;

        &:after {
          content: ' ';
          background: $color-black;
          width: 50%;
          height: 1px;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }

      @include respond-to(phone) {
        padding: $padding20;
      }
    }

    &.active {
      a {
        -webkit-text-stroke-color: $color-black;
      }
    }
  }

  .episodes-date {
    color: #fff;
    background: $color-black;
    display: inline-block;
    padding: 2px $padding20;
    margin-bottom: 3px;
    @include respond-to(pad) {
      font-size: $font-size-pad;
      margin-bottom: 0;
      padding: 4px $padding30;
    }
  }

  .load-more {
    span {
      font-size: 30px;
      border: 1px solid $color-black;
      padding: $padding20 $padding30;
      display: inline-block;
      min-width: 180px;
      text-align: center;
      cursor: pointer;
      @include respond-to(pad) {
        font-size: $font-size-pad-med;
        min-width: 52vw;
      }
    }
  }

  .show-archive-filter {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    font-size: 30px;
    background: $color-black;
    color: #fff;
    padding: $padding20 $padding30;
    cursor: pointer;
    width: calc(33.3333%);

    @include respond-to(pad) {
      width: 100%;
      font-size: $font-size-pad;
      padding: 15px 0;
    }

    @include respond-to(phone) {
      padding: 10px 0 8px !important;
    }
  }

  .archive-filter {
    background: $color-black;
    color: #fff;
    font-size: 30px;
  }

  .switch-filter-type, .reset-filter {
    font-size: 30px;
    padding: $padding20 $padding30;
    color: #fff;
    white-space: nowrap;
    border-bottom: 1px solid #fff;
    cursor: pointer;
    width: calc(33.3333%);
    position: fixed;
    top: $header-height;
    right: 0;
    left: 0;
    margin: 0 auto;
    background: $color-black;
    z-index: 2;
    border-bottom: 1px solid #fff;
    @include respond-to(pad) {
      width: 100%;
      font-size: $font-size-pad;
      padding: 15px 0;
    }
    @include respond-to(phone) {
      padding: 10px 0 8px !important;
    }
  }

  .reset-filter {
    z-index: 1;
  }

  .filter-bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    z-index: 9;
    background: $color-black;
    width: calc(33.3333%);
    @include respond-to(pad) {
      width: 100%;
    }
    .close-filter {
      font-size: 30px;
      padding: $padding20 10px;
      color: #fff;
      border-top: 1px solid #fff;
      cursor: pointer;

      @include respond-to(pad) {
        width: 100%;
        font-size: $font-size-pad;
        padding: 15px 0;
      }

      @include respond-to(phone) {
        padding: 10px 0 8px !important;
      }

      &.disabled {
        -webkit-text-stroke-color: $color-gray-disabled;
        cursor: inherit;
      }
    }
  }

  .date-filter {

    .rc-border {
      border-radius: 0 !important;
      border: none !important;
    }

    .rc-datepicker {
      font-size: 32px !important;
      @include respond-to(pad) {
        font-size: $font-size-pad-med !important;
      }
    }

    table {
      border-spacing: 0;
    }

    .table-condensed {
      width: 100%;
      table-layout: fixed;
      border-spacing:0;border-collapse:collapse
    }

    .day-enabled {
      display: none !important;
    }

    tbody td {
      border-right: 1px solid #fff;
      border-bottom: 1px solid #fff;

      @include respond-to(pad) {
        &:last-child {
          border-right: none;
        }
      }
    }

    thead {
      th {
        border-bottom: 1px solid #fff;
        font-size: 30px;
        padding: $padding20 15px !important;
        color: #fff;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: 1px;
        border-bottom: 1px solid #fff;
        @include respond-to(pad) {
          font-size: $font-size-pad;
          padding: 15px !important;
        }
        @include respond-to(phone) {
          padding: 10px 0 8px !important;
        }
      }

      .prev {
        text-align: left;
        @include respond-to(phone) {
          padding-left: 10px !important;
        }
      }

      .next {
        text-align: right;
        @include respond-to(phone) {
          padding-right: 10px !important;
        }
      }

      .disabled {
        -webkit-text-stroke-color: $color-gray-disabled;
      }
    }

    .calendar-day-wrapper {
      position: relative;

      .calendar-day {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &:after {
        content: '';
        padding-bottom: 100%;
        display: block;
      }
    }

    .today {
      .calendar-day {
        color: $color-red;
      }
    }

    .available {
      cursor: pointer;
    }

    .off {
      pointer-events: none;
      .calendar-day {
        color: $color-gray-disabled;
      }
    }

    .available.off {
      pointer-events: none;
      .calendar-day {
        color: $color-gray-disabled;
      }
    }

    .active {
      .calendar-day {
        color: #fff;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: 1px;
      }
    }
  }

  .program-filter {
    @include respond-to(pad) {
      font-size: $font-size-pad;
    }

    .rc-v-box, .rc-h-box {
      width: 100% !important;
    }

    .rc-input-text {
      @include respond-to(pad) {
        position: relative;
        position: sticky;
        top: calc(11vw + 70px);
        width: 100%;
        z-index: 1;
      }
      @include respond-to(phone) {
        top: calc(11vw + 60px);
      }
    }

    .rc-input-text-inner {
      border-bottom: 1px solid #fff;
      width: 100%;
      width: calc(33.3333%);
      position: fixed;
      top: 112px;
      right: 0;
      left: 0;
      margin: 0 auto;
      background: $color-black;
      z-index: 1;
      @include respond-to(pad) {
        position: relative;
        top: 0;
        width: 100%;
      }

      input {
        font-size: 30px;
        padding: $padding20 10px;
        color: #fff;
        background: transparent;
        border: none;
        width: 100%;
        font-family: "plakat", Arial, serif;
        text-align: center;
        border-radius: 0;
        &::-webkit-input-placeholder {color:$color-gray-disabled;}
        &::-moz-placeholder          {color:$color-gray-disabled;}
        &:-moz-placeholder           {color:$color-gray-disabled;}
        &:-ms-input-placeholder      {color:$color-gray-disabled;}
        outline: none;
        @include respond-to(pad) {
          font-size: $font-size-pad;
          padding: 15px !important;
        }
        @include respond-to(phone) {
          padding: 10px !important;
        }
      }
    }

    .rc-box {
      li {
        list-style: none;
        font-size: 30px;
        padding: $padding20 10px;
        color: #fff;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: 1px;
        border-bottom: 1px solid #fff;

        @include respond-to(pad) {
          font-size: $font-size-pad;
          padding: 15px !important;
        }

        &:empty {
          display: none;
        }
      }
    }

    .rc-typeahead-suggestion {
      cursor: pointer;
    }
  }
}