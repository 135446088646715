@import 'variables.scss';
@import 'mixins.scss';

$controls-width: 106px;

.player {
  position: absolute;
  top: 0;
  right: 0;
  left: $logo-width;

  .player-container {
    width: 100%;
    color: #fff;
    font-size: $font-size-small;
  }

  .archive-player, .live-player {
    display: flex;
    position: relative;
    height: $header-height;
  }

  .live-player {
    .player-control {
      &.playing {
        .player-control-body {
          border-radius: 0;
          width: 24px;
          height: 24px;
          background: #fff;
          border-width: 0;
        }
      }
    }
  }

  .archive-player {
    .player-control {
      &.playing {
        .player-control-body {
          border-radius: 0;
          width: 24px;
          height: 24px;
          background: transparent;
          border-width: 0;

          &:after, &:before {
            content: ' ';
            width: 10px;
            position: absolute;
            top: 0;
            bottom: 0;
            background: #fff;
          }

          &:after {
            left: 0;
          }

          &:before {
            right: 0;
          }
        }
      }
    }
  }

  .controls {
    flex: 0 0 $controls-width;

    .player-control {
      position: relative;
      width: $header-height;
      height: $header-height;
      cursor: pointer;
      display: inline-block;
    }

    .player-control-body {
      font-size: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 12px 0 12px 18px;
      border-color: transparent transparent transparent #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.disabled {
      .player-control-body {
    	border-color: transparent transparent transparent $color-gray-disabled;
      }
    }

    .player-switcher {
      position: relative;
      display: inline-block;
      width: 66px;
      height: $header-height;
      cursor: pointer;
      border-right: 1px solid #fff;
      border-left: 1px solid #fff;

      &.archive {
        .player-switcher-body {
          &:after {
            transform: translateX(0);
            background: #323232;
          }
        }
      }

      &.disabled {
        pointer-events: none;
        .player-switcher-body {
          border: 1px solid #323232;
          &:after {
            background: #323232;
          }
        }
      }
    }

    .player-switcher-body {
      width: 50px;
      height: 24px;
      border: 1px solid #fff;
      font-size: 0;
      display: block;
      border-radius: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &:after {
        content: '';
        background: #fff;
        border-radius: 100%;
        width: 18px;
        height: 18px;
        display: block;
        margin-top: 2px;
        margin-left: 2px;
        margin-right: 2px;
        transform: translateX(26px);
      }
    }
  }
}

.player-progress-container {
  width: 100%;
  position: relative;
}

.live-player {
  .player-progress-container {
    pointer-events: none;
  }
}

.archive-player {
  .player-name {
    z-index: 9;
  }
}

.player-progress {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 0;
  border: none!important;
  z-index: 1;
  padding: 0!important;
}

.live-player .player-progress-container .player-progress {
  background-color: rgba(255, 0, 0, 0.2);

  &.loading, &.playing {
    border-right: 2px solid $color-red !important;
  }

  &.loading {
    -webkit-animation: pulse 0.6s infinite alternate;

    @-webkit-keyframes pulse {
      0% {border-color: rgba(255, 0, 0, 0.2)}

      100% {border-color: rgba(255, 0, 0, 1);}
    }

  }

  &.playing {
    background-color: rgba(255,0,0,0.4);
  }
}

.archive-player .player-progress-container {
  input[type=range] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */
    position: absolute;
    outline: none;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
  }

  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 1px;
  }

  input[type=range]::-moz-range-thumb {
    -webkit-appearance: none;
    width: 1px;
    border: none;
    background: transparent;
  }


  input[type=range]:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  input[type=range]::-ms-track {
    width: 100%;
    cursor: pointer;
    height: 100%;
    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  input[type=range]::-webkit-slider-runnable-track {
    custor: pointer;
    position: relative;
    width: 100%;
    height: 100%;
    color: transparent;
  }

  input[type=range]::-moz-range-track {
    position: relative;
    width: 100%;
    height: 100%;
    color: transparent;
    background: transparent;
    outline: none;
  }

  input[type=range]::-ms-track {
    position: relative;
    width: 100%;
    height: 100%;
    color: transparent;
  }

  .player-progress {
    background-color: rgba(151, 151, 151, 0.4);

    &.loading, &.playing {
      border-right: 2px solid $color-red !important;
    }

    &.loading {
      -webkit-animation: pulse 0.6s infinite alternate;

      @-webkit-keyframes pulse {
	0% {border-color: rgba(255, 0, 0, 0.2)}

	100% {border-color: rgba(255, 0, 0, 1);}
      }

    }

    &.playing {
      background-color: rgba(151, 151, 151, 0.4);
    }
  }
}

.player-name {
  position: absolute;
  left: $controls-width + 18px;
  top: 13px;
  z-index: 0;
  a { color: #fff; }
  span {
    color: #e2e2e2;
    font-size: 12px;
  }
  @include respond-to(pad) {
    white-space: nowrap;
  }

  @include respond-to(phone) {
    display: none;
  }
}

.archive-player-timer {
  position: absolute;
  right: 18px;
  top: 13px;
  z-index: 0;
  @include respond-to(pad) {
    white-space: nowrap;
  }

}
